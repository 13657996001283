.wpcf7-form {
	p {
		margin: 0;
		text-align: left;

		&:last-of-type {
			@include span(4 of 4);
			text-align: center;
		}

		@include breakpoint($for-desktop-up) {
			@include span(2 of 4);
		}
	}

	label {
		line-height: 2rem;
		margin-bottom: 0;
	}

	input[type="checkbox"] {
		margin: 0 1em 0 0;
	}

	input[type="submit"] {
		margin: 1em 0 0 0;
	}

	.wpcf7-response-output.wpcf7-validation-errors,
	.wpcf7-response-output.wpcf7-acceptance-missing {
		position: relative;
		top: 1rem;
		clear: both;
		border: 0;
	    padding: 1rem;
    	color: #D8000C;
    	background-color: #FFBABA;
	}

	.wpcf7-response-output.wpcf7-mail-sent-ok {
		position: relative;
		top: 1rem;
		clear: both;
		border: 0;
	    padding: 1rem;
    	color: #4F8A10;
    	background-color: #DFF2BF;
	}

	.wpcf7-not-valid-tip {
		display: block;
		color: #D8000C;
		margin-top: -1rem;
		margin-bottom: 1rem;
	}
}

.screen-reader-response {
	border: 0;
    padding: 1rem;
    margin-bottom: 1rem;

    &[role="alert"] {
    	color: #D8000C;
    	background-color: #FFBABA;
    }

    ul {
    	display: none;
    }
}
