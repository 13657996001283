@font-face {
    font-family: 'harabara_maiharabaramaisBlkIt';
    src: url('../fonts/harabara_mais_black_italic-webfont.woff2') format('woff2'),
         url('../fonts/harabara_mais_black_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'harabara_mais_harabaramaisBlk';
    src: url('../fonts/harabara_mais_black-webfont.woff2') format('woff2'),
         url('../fonts/harabara_mais_black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'harabara_mais_bharabaramaisBd';
    src: url('../fonts/harabara_mais_bold-webfont.woff2') format('woff2'),
         url('../fonts/harabara_mais_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'harabara_mais_iharabaramaisIt';
    src: url('../fonts/harabara_mais_italic-webfont.woff2') format('woff2'),
         url('../fonts/harabara_mais_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'harabara_mais_lharabaramaisLt';
    src: url('../fonts/harabara_mais_light-webfont.woff2') format('woff2'),
         url('../fonts/harabara_mais_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'harabara_maisharabara_mais';
    src: url('../fonts/harabara_mais-webfont.woff2') format('woff2'),
         url('../fonts/harabara_mais-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
