.page-section {
    @include container(95%);
    @include font-smoothing(on);
    clear: both;
    padding: 3.75rem 0;
    position: relative;

    &.-solitude {
        background-color: color(solitude);
    }

    &.-alabaster {
        background-color: color(solitude);
    }

    &.-lesspadding {
        padding: 3rem 0;
    }

    &.-morepadding {
        padding: 6rem 0;
    }

    &.-nopaddingtop {
        padding-top: 0;
    }

    &.-nopaddingbottom {
        padding-bottom: 0;
    }

    > .inner {
        @include span(4 of 4 nest);
        text-align: left;

        > .title {
            margin-top: 0;
            font-size: 2.5rem;
            line-height: 3rem;
            font-weight: 300;
            text-align: center;
            margin-bottom: 1rem;
        }

        > .subtitle {
            font-size: 1.5rem;
            line-height: 1.618;
            text-align: center;
            margin-top: 0;
            color: color(black, lighten40);
            font-weight: normal;
        }

        > .lead-text {
            @include span(4 of 4);

            // center the button on mobiles but keep it left aligned on bigger devices
            > .button {
                display: block;
                text-align: center;

                @include breakpoint($for-tablet-landscape-up) {
                    display: inline-block;

                }
            }

            // the last paragraph should have a greater margin on the bottom
            p:last-of-type {
                margin-bottom: 3rem;
            }

            @include breakpoint($for-tablet-landscape-up) {
                @include span(6 of 12);
            }

            @include breakpoint($for-desktop-up) {
                @include span(4 of 12);
                @include push(1 of 12);
            }
        }

        > .image {
            @include span(4 of 4);
            overflow: hidden;

            img {
                @include img-responsive;
            }

            @include breakpoint($for-tablet-landscape-up) {
                @include span(6 at 6 of 12 last);

                img {
                    max-width: none;
                    width: auto;
                    max-height: 28rem;;
                    margin-top: 1.5rem;
                }
            }
        }

        > .video {
            width: 100%;
        }

        > .text-column {
            @include span(4 of 4);
            margin-top: 1rem;

            &:first-of-type {
                // margin-top: 2rem;
            }

            p:first-of-type {
                margin-top: 0;
            }

            p:last-of-type {
                margin-bottom: 0;
            }

            &.-team {
                img {
                    border-radius: 4px;
                }

                p {
                    text-align: center;
                }
            }

            > .question {
                position: relative;
                margin: 0 0 0.5rem 0;
                padding: 0 3rem;
                font-size: 1.125rem;
                font-weight: 600;

                &:before {
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 0.1875rem;
                    height: 1.5rem;
                    width: 1.5rem;
                    background-image: url('../images/faq-icon.svg');
                    background-size: 1.5rem 1.5rem;
                }
            }

            > .answer {
                margin: 0 0 2rem 0;
                padding: 0 1rem 0 3rem;
                font-size: 1.125rem;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        > .simple-cta {
            @include span(4 of 4);

            @include breakpoint($for-desktop-up) {
                @include span(8 of 12);
                @include push(2 of 12);
            }
        }

        > .team-section {
            padding-top: 2.5rem;
        }
    }

    @include breakpoint($for-desktop-up) {
        @include container(60em);

        &.-twocolumn {
            > .inner {
                @include span(12 of 12 nest);
                > .text-column {
                    @include span(5 of 12);
                    margin-top: 2rem;

                    &:nth-of-type(2n + 1) {
                        @include push(1 of 12);
                    }
                }

            }
        }
    }

    @include breakpoint($for-large-desktop-up) {
        @include container(75em);
    }
}