.select2-container {


    &--default {

        &.select2-container--focus {
          .select2-selection--multiple, .select2-selection--single {
            border: 2px solid lighten(color(shamrock, base), 15%);
          }
        }

        .select2-selection {
            &--multiple {
                font-size: 16px;
                padding: 0.35rem;
                border-radius: 6px;
                border: 2px solid color(alto, base);
                .select2-selection__rendered {
                    .select2-selection__choice {
                        background-color: color(shamrock, base);
                        border-color: darken(color(shamrock, base), 25%);
                        color: color(white, base);
                        font-size: 1rem;
                        font-weight: 300;
                        padding: 0 0.5rem;
                        .select2-selection__choice__remove {
                            color: darken(color(shamrock, base), 20%);
                            margin-right: 0.5rem;
                        }
                    }
                }
            }

            &--single {
                border-radius: 6px;
                border: 2px solid color(alto, base);
                padding: 0.75rem;
                padding-right: calc(0.75rem + 32px);
                height: auto;
                background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAlCAQAAAAQRvp5AAAAgklEQVRIx+3SsQmDYBAGUGsJ2DmKAziBIziB4zmB8EpJI7iAA9gpyKVJkVJNEQj/dV/xivvusnnPvpk5Ek888d9yo0n5kUuT8TxfhKfinQpPYTnPK6swyLNMbhBW1YXd1Tah99ALm/pidRqHsAiH5kbzWiGE9ubhdHZdetrEE/8T/gJUi4lr4iaW6QAAAABJRU5ErkJggg==") #fff right center no-repeat;

                .select2-selection__rendered {
                    padding: 0;
                    line-height: 1.5rem;
                }
                .select2-selection__arrow {
                    display: none;
                }
            }
        }


        .select2-results {

            &__option {
                font-size: 1rem;
                &[aria-selected=true] {
                    background-color: lighten(color(shamrock, base), 40%);
                }
                &--highlighted[aria-selected] {
                    background-color: color(shamrock, base);
                }
            }
        }
    }
}
