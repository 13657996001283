.primary-navigation {
    @include span(4);
    display: none;

    li {
        position: relative;
        &.new {
            &::after {
                content: '';
                position: absolute;
                width: 0.5rem;
                height: 0.5rem;
                top: 0.55rem;
                right: -1.05rem;
                border-radius: 1rem;
                background: color(violetred, base);
            }
        }
    }

    > .menu {
        list-style: none;
        margin: 0;
        padding: 0;

        @include breakpoint($for-desktop-up) {
            float: right;
        }
    }

    li {
        line-height: 3;
        @include font-smoothing(on);
        @include breakpoint($for-desktop-up) {
            float: left;
            display: inline;
            line-height: 1.5;
            font-size: 1.1rem;
            margin-left: 2.25rem;
            padding: 0.25rem;
        }

        &.active, &.current-menu-item  {
            border-bottom: 2px solid color(white, base);
        }

        > a {
            display: block;
            text-decoration: none;
            color: color(white, base);

            &:hover {
                border-bottom: 2px solid color(white, base);
            }
        }

        &.button {
            &.active, &.current-menu-item  {
                border-bottom: 0 none;
            }

            a:hover {
                border-bottom: 0 none;
            }
        }
    }

    @include breakpoint($for-desktop-up) {
        @include with-layout($twelve-columns);
        @include span(10 at 2 of 12);
        display: block;
    }
}