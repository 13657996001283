.profile-container {
    @include container(95%);
    @include font-smoothing(on);

    &.-solitude {
        background-color: color(solitude);
    }

    > ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    > .pagination-container {
        @include container(100%);

        > .pagination {
            @include span(4 of 4);
            margin-bottom: 2rem;
        }
    }

    @include breakpoint($for-desktop-up) {
        @include container(60em);
    }

    @include breakpoint($for-large-desktop-up) {
        @include container(75em);
    }
}
