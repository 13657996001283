html {
    height: 100%;
    box-sizing: border-box;
    color: #333;
}

body {
    height: 100%;
    background-color: color(background);

    &.modal-present {
        overflow: hidden;
    }
}

p {
    font-size: 1.125rem;
}

a {
    text-decoration: none;
    color: color(bluemine, base);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

label {
    font-size: 1rem;
    display: block;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;

    &.error {
        color: rgba(169,68,66,1);
    }

    &.required::after {
        content: " (Pflichtfeld)";
        color: color(black, lighter50);
    }
}

blockquote {
    display: block;
    font-size: 1.75rem;
    width: 100%;
    margin: 0;
    text-align: center;
    padding: 0 1rem;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="date"],
textarea {
    appearance: none;
    display: block;
    font-size: 16px;
    padding: 0.75rem;
    border: 2px solid color(alto, base);
    background-color: color(white, base);
    border-radius: 6px;
    width: 100%;
    margin-bottom: 1rem;

    &.error {
        border-color: rgba(169,68,66,1);
        color: rgba(169,68,66,1);
    }

    &:focus {
        border: 2px solid lighten(color(shamrock, base), 15%);
        outline: none;
    }
}

input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    + label {
        display: inline-block;
        background-color: color(shamrock, base);
        color: color(white, base);
        cursor: pointer;
    }
}

ol,
ul {

    font-size: 1.125rem;

    &.unstyled {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    font-size: 16px;
    padding: 0.75rem;
    padding-right: calc(0.75rem + 32px);
    border: 2px solid color(alto, base);
    background-color: #fff;
    border-radius: 6px;
    max-width: 100%;
    width: 100%;
    margin-bottom: 1rem;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAlCAQAAAAQRvp5AAAAgklEQVRIx+3SsQmDYBAGUGsJ2DmKAziBIziB4zmB8EpJI7iAA9gpyKVJkVJNEQj/dV/xivvusnnPvpk5Ek888d9yo0n5kUuT8TxfhKfinQpPYTnPK6swyLNMbhBW1YXd1Tah99ALm/pidRqHsAiH5kbzWiGE9ubhdHZdetrEE/8T/gJUi4lr4iaW6QAAAABJRU5ErkJggg==") #fff right center no-repeat;

    &:focus {
        border: 2px solid lighten(color(shamrock, base), 15%);
        outline: none;
    }
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

*:focus {
    outline: none;
}

main {
    height: 100%;
}
