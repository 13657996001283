.company-box {
    @include container(100%);
    padding: 1rem 0;

    > .profile-image {
        @include span(4 of 4);
        text-align: center;

        > .image {
            display: none;
            border: 3px solid color(bluemine, base);
            border-radius: 10rem;
            height: 4rem;
            width: 4rem;
            margin: 0 auto;

            @include breakpoint($for-tablet-landscape-up) {
                position: relative;
                display: block;
                height: 7rem;
                width: 7rem;

                > .inner {
                    width: 4.68rem;
                    height: 4.68rem;
                    @include centered(true, true);
                }
            }
        }

        @include breakpoint($for-tablet-landscape-up) {
            @include span(2 of 12);
        }
    }

    > .content {
        @include span(4 of 4 nest);

        > .header {
            @include span(4 of 4);

            > .title {
                margin: 1rem 0;
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.5;

                @include breakpoint($for-tablet-landscape-up) {
                    margin-top: 0;
                }
            }
        }

        > .column {
            @include span(4 of 4);

            > .term {
                font-weight: 600;
            }

            > .definition {
                margin-left: 0;
                margin-bottom: 0.5rem;

                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                }
            }
        }

        > .left-column {
            @include span(4 of 4);

            @include breakpoint($for-tablet-landscape-up) {
                @include span(6 of 12);
            }
        }

        > .right-column {
            @include span(4 of 4);

            @include breakpoint($for-tablet-landscape-up) {
                @include span(6 of 12);
            }
        }

        > .actions {
            @include span(4 of 4);
            margin-top: 1.5rem;

            > form {
                float: left;
                display: inline-block;
                margin: 0;
                &:after {
                    clear:both;
                    content: ' ';
                }
            }

            > .raised-button,
            > form > .raised-button {
                display: inline-block;
                margin-right: 0.5rem;
            }
        }

        @include breakpoint($for-tablet-landscape-up) {
            @include span(10 of 12 nest);
        }
    }
}
