.modal-box {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    outline: 0;
    background: transparent;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: auto;
    transition:  transform 0.5s cubic-bezier(.10, .70, .10, 1);
    transform: translateX(100%);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    opacity: 0;

    &.open {
        transform: translateX(0%);
        background: color(solitude) url('../images/background-hero.png') no-repeat left bottom;
        background-size: cover;
        opacity: 1;

        @include breakpoint($for-tablet-landscape-up) {
            background: transparentize(color(black, base), 0.3);
            transform: translateY(0%);
        }
    }

    > .top-bar {
        @include container(100%);
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: color(dodgerblue, base);
        padding: 1rem;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        > .title {
            font-size: 1.5rem;
            font-weight: 300;
            // color: #525252;
            color: color(white, base);
            // border-bottom: 1px solid color(white, darker10);
            margin: 0;
        }

        > .close {
            @extend .fa;
            @extend .fa-close;
            position: absolute;
            box-sizing: border-box;
            z-index: 1010;
            top: 1rem;
            right: 1rem;
            font-size: 1.5em;
            line-height: 1;
            color: color(white, base);
            -webkit-tap-highlight-color: rgba(0,0,0,0);
            background: none;
            border: none;
            cursor: pointer;
        }

        @include breakpoint($for-tablet-landscape-up) {
            @include container(48em);
        }
    }

    > .inner {
        @include container(100%);
        flex: 1 1 auto;
        position: relative;
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        background: color(white, base);
        box-shadow: 0 1px 4px 0 rgba(119,135,147,0.1), 0 20px 40px 0 rgba(119,135,147,0.15);

        .section {
            padding: 1rem;

            .date {
                display: flex;
                flex-direction: column;

                @include breakpoint($for-tablet-portrait-up) {
                    flex-direction: row;
                    justify-content: space-between;
                }

                > select {
                    display: inline-block;

                    @include breakpoint($for-tablet-portrait-up) {
                        width: calc(1/3*100% - (1 - 1/3)*1rem);
                    }
                }
            }

            &.-even {
                background: color(solitude);
            }

            &.-odd {
                background: color(white, base);
            }

            &.-new {
                background: transparentize(color(shamrock, base), 0.9);
            }
        }

        .subtitle {
            font-size: 1.25rem;
            font-weight: 300;
            color: lighten(color(black, base), 32%);
            margin: 1rem 0;
        }

        .prototype-form {
            display: none;
        }

        .auxiliary-actions {
            padding: 1rem;
            text-align: center;
            background: color(white, base);
        }

        @include breakpoint($for-tablet-landscape-up) {
            @include container(48em);
        }
    }

    > .actions {
        @include container(100%);
        width: 100%;
        position: relative;
        background: color(white, base);
        text-align: right;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        padding: 2rem 1rem 1rem;
        border-top: 2px solid color(bluemine, base);

        > .cancel {
            margin-right: 1rem;
            cursor: pointer;
        }

        @include breakpoint($for-tablet-landscape-up) {
            @include container(48em);
        }
    }

    @include breakpoint($for-tablet-landscape-up) {
        transform: translateX(0%);
        transform: translateY(100%);
    }

    .dateOfBirth select, .permitValidTo select {
        width: auto;
        float: left;

        &:last-of-type {
            float: none;
        }
    }

    .-hide {
        display: none;
    }
}
