.simple-cta {
    position: relative;
    text-align: center;

    > .text {
        margin-top: 0;
        margin-bottom: 2rem;
        font-size: 1.5rem;
        line-height: 1.5;
        text-align: center;
    }
}