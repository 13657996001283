.cookie-alert {
   outline: 1px solid #7b92a9; 
   text-align:right; 
   border-top:1px solid #fff;
   background: #d6e0eb; 
   background: -moz-linear-gradient(top, #d6e0eb 0%, #f2f6f9 100%); 
   background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d6e0eb), color-stop(100%,#f2f6f9)); 
   background: -webkit-linear-gradient(top, #d6e0eb 0%,#f2f6f9 100%); 
   background: -o-linear-gradient(top, #d6e0eb 0%,#f2f6f9 100%);
   background: -ms-linear-gradient(top, #d6e0eb 0%,#f2f6f9 100%); 
   background: linear-gradient(to bottom, #d6e0eb 0%,#f2f6f9 100%); 
   filter: progid:DXImageTransform.Microsoft.gradient(   startColorstr='#d6e0eb', endColorstr='#f2f6f9',GradientType=0 ); 
   position:fixed;
   bottom:0px; 
   z-index:10000; 
   width:100%; 
   font-size:12px; 
   line-height:16px;

   div {
      padding: 1rem 3rem 1rem 1rem;
   }

   .close {
      color: #777;
      position: absolute;
      right: 5px;
      text-decoration: none;
      text-shadow: 0 1px 0 #fff;
      top: 5px;
      cursor:pointer;
      border-top:1px solid white; 
      border-left:1px solid white; 
      border-bottom:1px solid #7b92a9; 
      border-right:1px solid #7b92a9; 
      padding:4px;
      background: #ced6df;

      &:hover {
         border-bottom:1px solid white; 
         border-right:1px solid white; 
         border-top:1px solid #7b92a9; 
         border-left:1px solid #7b92a9;
      }
   }
}
