.pagination {
    list-style: none;
    margin: 0 0 0.75rem 0;
    padding: 0;
    text-align: center;

    > li {
        display: inline-block;
        height: 2rem;
        min-width: 2rem;
        cursor: pointer;
        border-radius: 2px;
        text-align: center;
        font-size: 1.125rem;
        line-height: 2rem;

        &.current, &:hover {
            background: color(shamrock, base);
            color: color(white, base);

            > a {
                color: color(white, base);
            }
        }

        > a {
            width: 100%;
            text-decoration: none;
            padding: 0 0.5rem;
        }
    }
}