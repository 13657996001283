.button {
    @include font-smoothing(on);
    display: inline-block;
    min-width: 5.5rem;
    padding: 0.5rem 1.5rem;
    color: color(black, lighter20);
    background-color: color(white, base);
    text-decoration: none;
    border: 3px solid color(white, base);
    border-radius: 5rem;
    cursor: pointer;
    transition: all .15s ease;

    &:hover, &:active, &:focus {
        text-decoration: none;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.50);
        transform: translateY(-1px);
    }

    &.-small {
        padding: 0.25rem 1rem;
        font-size: 1rem;
        border-width: 2px;
    }

    &.-medium {
        font-size: 1.25rem;
    }

    &.-large {
        font-size: 1.5rem;
    }

    &.-fab {
        width: 3.25rem;
        height: 3.25rem;
        min-width: initial;
        border-radius: 50%;
        padding:0;
        user-select: none;
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        border-width:0;

        > i {
            font-size: 1.5rem;
            line-height: 3.25rem;
        }
    }

    &.-primary {
        @include font-smoothing(on);
        background-color: color(violetred, base);
        border-color: color(violetred, base);
        color: color(white, base);

        &:hover, &:active, &:focus {
            background-color: lighten(color(violetred, base), 5%);
            color: rgba(255,255,255,1);
            box-shadow: 0 0 2px 0 transparentize(color(violetred, base), 0.5);
        }
    }

    &.-success {
        border-color: color(shamrock, base);
        background-color: color(shamrock, base);
        color: color(white, base);

        &:hover, &:active, &:focus {
            background-color: lighten(color(shamrock, base), 5%);
            color: rgba(255,255,255,1);
            box-shadow: 0 0 2px 0 transparentize(color(shamrock, base), 0.5);
        }
    }

    &.-default {
        border-color: color(dodgerblue, base);
        background-color: color(dodgerblue, base);
        color: color(white, base);

        &:hover, &:active, &:focus {
            background-color: lighten(color(dodgerblue, base), 5%);
            color: rgba(255,255,255,1);
            box-shadow: 0 0 2px 0 transparentize(color(dodgerblue, base), 0.5);
        }
    }

    &.-ghost {
        background-color: rgba(255,255,255,0);
        color: inherit;

        &:hover, &:active, &:focus {
            background-color: rgba(255,255,255,0.2);
        }
    }

    &.-flat {
        padding: 0.5rem;
        background-color: transparent;
        border-color: transparent;
        border-radius: 0.125rem;
        color: inherit;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 600;
    }

}