.tab-container {
    @include container(95%);
    @include font-smoothing(on);
    margin-bottom: 2rem;
    box-shadow: 0 1px 4px 0 rgba(119,135,147,0.1), 0 5px 10px 0 rgba(119,135,147,0.15);
    position: -webkit-sticky;
    position: sticky;
    z-index: 5;
    top: 5.25rem;

    > .tabs {
        width: auto;
        list-style: none;
        padding: 0;
        margin: 0 auto;
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
        height: 3rem;
        white-space: nowrap;
        background: color(white, base);

        > .tab {
            position: relative;
            display: inline-block;
            text-align: center;
            line-height: 3rem;
            height: 3rem;
            padding: 0;
            margin: 0;

            &.new {
                &:after {
                    content: '';
                    position: absolute;
                    width: 0.5rem;
                    height: 0.5rem;
                    top: 0.55rem;
                    right: -0.5rem;
                    border-radius: 1rem;
                    background: color(violetred, base);
                }
            }
            &.-active {
                > a {
                    border-bottom: 3px solid color(shamrock, base);
                }
            }

            > a {

                text-decoration: none;
                width: 100%;
                height: 100%;
                display: block;
                padding: 0 1.5rem;
                margin: 0;
                font-size: 1.125rem;
                overflow: hidden;

                &:hover {
                    border-bottom: 3px solid color(shamrock, base);
                }
            }
        }
    }

    @include breakpoint($for-desktop-up) {
        @include container(60em);
    }

    @include breakpoint($for-large-desktop-up) {
        @include container(75em);
    }
}
