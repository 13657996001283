.workreport-box {
    @include container(100%);
    padding: 1rem 0;

    > .actions {
        @include span(4 of 4);

        @include breakpoint($for-tablet-landscape-up) {
            @include span(2 of 12);
        }
    }

    > .content {
        @include span(4 of 4 nest);

        > .header {
            @include span(4 of 4);

            > .title {
                margin: 1rem 0;
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.5;

                @include breakpoint($for-tablet-landscape-up) {
                    margin-top: 0;
                }
            }
        }

        > .column {
            @include span(4 of 4);
        }

        > .left-column {
            @include span(4 of 4);

            @include breakpoint($for-tablet-landscape-up) {
                @include span(6 of 12);
            }
        }

        > .right-column {
            @include span(4 of 4);

            @include breakpoint($for-tablet-landscape-up) {
                @include span(6 of 12);
            }
        }

        > .actions {
            @include span(4 of 4);
            margin-top: 1.5rem;

            > form {
                float: left;
                display: inline-block;
                margin: 0;
                &:after {
                    clear:both;
                    content: ' ';
                }
            }

            > .raised-button,
            > form > .raised-button {
                display: inline-block;
                margin-right: 0.5rem;
            }
        }

        s {
            color: color(violetred, base);
        }
    }

    hr {
        clear: both;
    }
}

.workreport-create-form {
    .floating {

        div {
            float:left;
            width: 33%;
        }
    }
}

ul.workreport {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.workreport-admin-table-box {
    overflow-x: auto;

    table {
        width: 100%;
        border-collapse: collapse;

        th, td {
            border: 1px solid #000;
            padding: 0 0.5rem;
            width: 12.5%;
        }

        th:first-of-type {
            text-align: left;
        }
    }
}
