.toggle-button {
    @extend .fa;
    @extend .fa-bars;
    color: color(white, base);
    line-height: 2em;
    font-size: 1.5em;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    padding-left: 1em;
    
    @media (min-width: 980px) {
		display: none;
	}
}