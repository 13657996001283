// Normalize stuff
$base-unit: 'rem';
$base-font-family: 'harabara_maisharabara_mais';
@include normalize();

// Breakpoints
$for-phone-only: 0em 35.9375em;
$for-tablet-portrait-up: 36em;
$for-tablet-landscape-up: 48em;
$for-desktop-up: 62em;
$for-large-desktop-up: 75em;


$breakpoints: (
    small-phone: 20em,
    normal-phone: 23.4375em,
    tablet-portrait: 36em,
    tablet-landscape: 48em,
    desktop: 62em,
    large-desktop: 75em
);

$typi: (
    base: (
        null: (16px, 1.618),
        small-phone: (12px),
        normal-phone: (14px),
        tablet-portrait: (16px)
    )
);
@include typi-init;

// Susy stuff
$susy: (
    columns: 4,
    column-width: 106.6666px,
    gutters: 32px/106.6666px,
    gutter-position: inside-static,
    global-box-sizing: border-box,
    use-custom: (rem: true)
);


$twelve-columns: (
    columns: 12,
    column-width: 106.6666px,
    gutters: 32px/106.6666px,
    gutter-position: inside-static,
    global-box-sizing: border-box,
    use-custom: (rem: true)
);

// $color-solitude: #f8f9fb;
$color-background: #f5f7fa;
$color-solitude: #fafafa;
$color-black: #000;
$color-white: #fff;
$color-bluemine: #19687f;
$color-alto: #d9d9d9;
$color-violetred: #fc4a80;
$color-dodgerblue: #5ccaf2;
$color-shamrock: #56c267;

$colors: (
    background: $color-background,
    solitude: $color-solitude,
    black: (
        base: $color-black,
        lighter10: lighten($color-black, 10%),
        lighter20: lighten($color-black, 20%),
        lighter30: lighten($color-black, 30%),
        lighter40: lighten($color-black, 40%),
        lighter50: lighten($color-black, 50%)
    ),
    white: (
        base: $color-white,
        darker10: darken($color-white, 10%),
        darker20: darken($color-white, 20%),
        darker30: darken($color-white, 30%),
        darker40: darken($color-white, 40%),
        darker50: darken($color-white, 50%)
    ),
    bluemine: (
        base: $color-bluemine,
        dark: darken($color-bluemine, 15%),
        darker: darken($color-bluemine, 30%),
        light: lighten($color-bluemine, 15%),
        lighter: lighten($color-bluemine, 30%),
        trans50: transparentize($color-bluemine, 0.5),
        trans35: transparentize($color-bluemine, 0.35),
        trans30: transparentize($color-bluemine, 0.3)
    ),
    alto: (
        base: $color-alto,
        dark: darken($color-alto, 15%),
        darker: darken($color-alto, 30%),
        light: lighten($color-alto, 15%),
        lighter: lighten($color-alto, 30%),
        trans50: transparentize($color-alto, 0.5),
        trans35: transparentize($color-alto, 0.35),
        trans30: transparentize($color-alto, 0.3)
    ),
    violetred: (
        base: $color-violetred,
        dark: darken($color-violetred, 15%),
        darker: darken($color-violetred, 30%),
        light: lighten($color-violetred, 15%),
        lighter: lighten($color-violetred, 30%),
        trans50: transparentize($color-violetred, 0.5),
        trans35: transparentize($color-violetred, 0.35),
        trans30: transparentize($color-violetred, 0.3)
    ),
    dodgerblue: (
        base: $color-dodgerblue,
        dark: darken($color-dodgerblue, 15%),
        darker: darken($color-dodgerblue, 30%),
        light: lighten($color-dodgerblue, 15%),
        lighter: lighten($color-dodgerblue, 30%),
        trans50: transparentize($color-dodgerblue, 0.5),
        trans35: transparentize($color-dodgerblue, 0.35),
        trans30: transparentize($color-dodgerblue, 0.3)
    ),
    shamrock: (
        base: $color-shamrock,
        dark: darken($color-shamrock, 15%),
        darker: darken($color-shamrock, 30%),
        light: lighten($color-shamrock, 15%),
        lighter: lighten($color-shamrock, 30%),
        trans50: transparentize($color-shamrock, 0.5),
        trans35: transparentize($color-shamrock, 0.35),
        trans30: transparentize($color-shamrock, 0.3)
    )
);

@function color($color-name, $color-variant:null) {
    @if ($color-variant != null) {
        // map inception
        @return map-get(map-get($colors, $color-name), $color-variant);
    } @else {
        @return map-get($colors, $color-name);
    }
}