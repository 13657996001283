.raised-button {
    @include font-smoothing(on);
    box-sizing: border-box;
    display: inline-block;
    margin: 0.25rem 0 0 0;
    padding: 0.625rem 1rem;
    background-color: color(dodgerblue, base);
    border: 1px solid color(dodgerblue, base);
    border-radius: 0.25rem;
    color: color(white, base);
    text-transform: uppercase;
    font-size: 0.9375rem;
    line-height: 0.9375;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
    text-decoration: none;
    transition: all .15s ease;

    &:hover, &:focus {
        transform: translateY(-1px);
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
        text-decoration: none;
    }

    &:active {
        transform: translateY(1px);
        box-shadow: none;
    }

    &.-dismissive {
        background-color: color(violetred, base);
        border-color: color(violetred, base);
    }

    &.-affirmative {
        background-color: color(shamrock, base);
        border-color: color(shamrock, base);
    }

    &.-clear {
        background-color: transparent;
        border-color: color(alto, base);
        color: inherit;
    }

    &.-disabled {
        opacity: 0.33;
        cursor: auto;
    }

    @include breakpoint($for-tablet-landscape-up) {
        box-shadow: none;
    }
}
