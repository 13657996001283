.profile-card {
    @include span(4 of 4);
    @include font-smoothing(on);
    position: relative;
    background-color: #fff;
    border-radius: 2px;
    margin-bottom: 0.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 1px 4px 0 rgba(119,135,147,0.1), 0 5px 10px 0 rgba(119,135,147,0.15);
    font-size: 1.125rem;

    p {
        margin-top: 0.5rem;
    }

    &.-nesting {
        padding: 0;
    }

    > .title {
        font-size: 1.5rem;
        font-weight: 400;
        color: #525252;
        // border-bottom: 1px solid color(white, darker10);
        // padding-bottom: 1rem;
        margin: 0 0 1rem 0;
        line-height: 1.5;
    }

    > .subtitle {
        font-size: 1.25rem;
        font-weight: 300;
        color: lighten(color(black, base), 32%);
        margin: 2rem 0 0.5rem;
    }

    > .status {
        @extend .fa;
        position: absolute;
        right: 1rem;
        top: 0.75rem;
        line-height: 1.5em;
        font-size: 1.5em;

        &.-approved, &.-billed, &.-paid {
            @extend .fa-check;
            color: color(shamrock, base);
        }

        &.-review {
            @extend .fa-clock-o;
            color: color(dodgerblue, base);
        }

        &.-rejected {
            @extend .fa-exclamation;
            color: color(violetred, base);
        }

        &.-new, &.-reviewing, &.-reviewed {
            @extend .fa-clock-o;
            color: color(dodgerblue, base);
        }

        &.-contract {
            @extend .fa-check;
            color: color(shamrock, base);
        }

        &.-cancel {
            @extend .fa-bell-o;
            color: color(dodgerblue, base);
        }

        &.-cancelled {
            @extend .fa-ban;
            color: color(violetred, base);
        }
    }
}
