.panel {
	height: 0;
	display: none;
	transition: height 0.4s ease-out;

	&.-open {
		display: block;
		height: auto;
	}	
}

.js-accordion {
	cursor: pointer;

	i {
		@extend .fa;
		@extend .fa-chevron-down;
        float: right;
        font-size: 1.5em;
	}

	&.-open {
		i {
			@extend .fa-chevron-up;
		}
	}
}
