@mixin font-smoothing($value: on) {
    @if $value == on {
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

@mixin centered($horizontal: true, $vertical: true) {
    position: absolute;
    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @else if ($horizontal) {
        left: 50%;
        transform: translate(-50%, 0);
    }
    @else if ($vertical) {
        top: 50%;
        transform: translate(0, -50%);
    }
}

@mixin img-responsive() {
    display: block;
    max-width: 100%;
    height: auto;
}