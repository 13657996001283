.hourly-wage-calculator {
	.calculator-table {
	    width: 100%;
	    margin: 3.5rem 0 0;
	    text-align: center;
	    border-collapse: collapse;
	    background-color: color(white, base);
	    box-shadow: 0 1px 4px 0 rgba(119,135,147,0.1), 0 5px 10px 0 rgba(119,135,147,0.15);
	    table-layout: fixed;
	    border-radius: 4px;

	    > thead {
	        display: table-header-group;
	        background-color: color(shamrock, base);
	        vertical-align: middle;
	        color: color(white, base);

	        > tr {
	            > th {
	                &:first-of-type {
	                    padding-left: 2rem;
	                    text-align: left;
	                    font-size: 1.25rem;
	                    border-radius: 4px 0 0 0;
	                    width: 35%;
	                }
	                &:nth-of-type(2) {
	                	width: 40%;
	                }

	                &:last-of-type {
	                	padding-right: 2rem;
	                    border-radius: 0 4px 0 0;
	                }

	                font-weight: 600;
	                font-size: 1.25rem;
	                line-height: 1.5;
	                padding: 0;
	            }
	        }
	    }

	    > tbody {
	        display: table-row-group;

	        select {
				margin: 0;
				padding: 0.25rem;
			}

			input {
				margin: 0;
				padding: 0.25rem;
				text-align:right;
			 	&[readonly] {
			 		border: 0 none;
			 		background: none;
			 	}
			}

	        > tr {
	            display: table-row;
	            &:nth-of-type(odd) {
	                background-color: color(solitude);
	            }

	            > th {
	                font-weight: 600;
	                padding-left: 2rem;
	                text-align: left;
	            }

	            > td {
	                padding: 0;
	                &:last-of-type {
	                	padding-right: 2rem;
	                }

	                &:nth-of-type(2) {
	                	padding-left: 2rem;
	                }
	            }

	            &:last-of-type {
	                > th {
	                    border-radius: 0 0 0 4px;
	                }

	                > td:last-of-type {
	                    border-radius: 0 0 4px 0;
	                }
	            }

	            &.basic_wage {
	            	>th {
	                	&:nth-of-type(2), &:nth-of-type(3) {
	                		display:none;
	                	}
	                }
	            }
	        }
	    }

	    &[data-selected-column='options'] {
	        > thead {
	            th:nth-of-type(2) {
	                border-radius: 0 4px 0 0;
	            }

	            th:nth-of-type(3), th:nth-of-type(4) {
	                display: none;
	            }
	        }

	        > tbody {
	            > tr {
	            	td:nth-of-type(1) {
	            		padding-right: 2rem;
	            	}

	                td:nth-of-type(2), td:nth-of-type(3) {
	                    display: none;
	                }

	                &:last-of-type {
	                    td:nth-of-type(1) {
	                        border-radius: 0 0 4px 0;
	                    }
	                }

	                &.basic_wage {
		            	>th {
		            		&:nth-of-type(3) {
		            			display: table-cell;
		            		}

		                	&:nth-of-type(1), &:nth-of-type(2) {
		                		display:none;
		                	}
		                }

		                >td:last-of-type {
		                	display: table-cell;
		                }
		            }
	            }
	        }
	    }

	    &[data-selected-column='cost'] {
	        > thead {
	            th:nth-of-type(4) {
	                border-radius: 0 4px 0 0;
	            }

	            th:nth-of-type(2){
	                display: none;
	            }
	        }

	        > tbody {
	            > tr {
	                td:nth-of-type(1) {
	                    display: none;
	                }

	                &:last-of-type {
	                    td:nth-of-type(2) {
	                        border-radius: 0 0 4px 0;
	                    }
	                }

	                &.basic_wage {
		            	>th {
		            		&:nth-of-type(2) {
		            			display: table-cell;
		            		}

		                	&:nth-of-type(1), &:nth-of-type(3) {
		                		display:none;
		                	}
		                }

		                >td:last-of-type {
		                	display: table-cell;
		                }
		            }
	            }
	        }
	    }

	    @include breakpoint($for-desktop-up) {
	        &[data-selected-column='options'],
	        &[data-selected-column='cost'] {
	            > thead {
	                th:first-of-type {
	                    width: 25%;
	                }

	                th:nth-of-type(2), th:nth-of-type(3) {
	                    display: table-cell;
	                    border-radius: 0;
	                }

	                th:last-of-type {
	                    border-radius: 0 4px 0 0;
	                }
	            }

	            > tbody {
	                > tr {
	                    td:nth-of-type(1), td:nth-of-type(2), td:nth-of-type(3) {
	                        display: table-cell;
	                    }

	                    &.basic_wage {
			            	>th {
			            		&:first-of-type {
			            			display: table-cell;
			            		}

			                	&:nth-of-type(2), &:nth-of-type(3) {
			                		display:none;
			                	}
			                }
			            }
	                }

	            }
	        }

	    }
	}

	@include breakpoint($for-desktop-up) {
        @include container(50em);

        background-color: color(white, base);
        box-shadow: 0 1px 4px 0 rgba(119,135,147,0.1), 0 20px 40px 0 rgba(119,135,147,0.15);
    }

    @include breakpoint($for-large-desktop-up) {
        @include container(65em);
    }

	.calculator-subnav {
	    > .link {
	        display: inline-block;
	        margin-right: 0.375rem;
	        background-color: color(white, base);
	        color: color(bluemine, base);
	        text-transform: uppercase;
	        padding: 0.375rem;
	        border-radius: 0.25rem;

	        &.active, &:hover {
	            text-decoration: none;
	            background-color: color(bluemine, base);
	            color: color(white, base);
	        }

	        &:last-of-type {
	            margin-right: 0;
	        }
	    }

	    @include breakpoint($for-desktop-up) {
	        display: none;
	    }
	}
}

.profile-card {
	.hourly-wage-calculator {
		.calculator-table {
			box-shadow: none;
		    border-radius: 0;

		    > thead {
		        > tr {
		            > th {
		                &:first-of-type, &:last-of-type {
		                    border-radius: 0;
		                }
					}
		        }
		    }
		}
	    @include breakpoint($for-desktop-up) {
	        box-shadow: none;
	    }
	}
}
