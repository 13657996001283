.login-box {
    @include container(100%);
    padding: 6rem 0 1.5rem;
    > .inner {
        @include span(4 of 4);
        > .title {
            margin-top: 0;
            font-size: 3.375rem;
            line-height: 3.75rem;
            font-weight: 300;
            text-align: center;
            margin-bottom: 1rem;
        }

        > .subtitle {
            font-size: 1.125rem;
            text-align: center;
            margin-top: 0;
            color: #666;
            font-weight: normal;
        }

        > .login-form {
            margin: 2rem 0;
            padding: 3rem 2rem;
            border-radius: 6px;
            background-color: color(white, base);
            box-shadow: 0 1px 4px 0 rgba(119,135,147,0.1), 0 20px 40px 0 rgba(119,135,147,0.15);

            > .button {
                margin-top: 1rem;
                width: 100%;
            }
        }
    }


    @include breakpoint($for-desktop-up) {
        padding: 7.5rem 0 1.5rem;
        @include container(60em);

        > .inner {
            @include span(5 of 12);
            float: none;
            margin: 0 auto;
        }
    }

    @include breakpoint($for-large-desktop-up) {
        @include container(75em);
    }
}
