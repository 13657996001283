.site-footer-wrapper {
    position: relative;
    top: -10rem;
    z-index: 1;
    background: url('../images/texture.png'), linear-gradient(0.25turn, #5ccaf2, #5cc699, #56c267);

    > .site-footer {
        padding: 11.5rem 0 1.5rem 0;
        color: color(white, base);
        border: 0 none;
    }
}

.site-footer {
    @include container(100%);
    padding: 1.5rem 0;
    border-top: 1px solid rgba(0,0,0,0.1);
    color: color(black, lighten40);

    > .inner {
        @include span(4 of 4);

        > .menu {
            list-style: none;
            margin: 0 0 0 3rem;
            padding: 0;
            display: inline-block;

            &.-copyright {
                position: absolute;
                bottom: 0;
                right: 0;
                margin: 1em;
            }

            li {
                @include font-smoothing(off);
                line-height: 1.5;
                font-size: 1rem;

                @include breakpoint($for-large-desktop-up) {
                    margin-left: 3rem;

                    &:last-of-type {
                        margin-right: 3rem;
                    }
                }
            }
        }

        >.contacts {
            list-style: none;
            margin: 0 0 1em 0;
            padding: 0;
            float: left;
            display: inline-block;

            li {
                @include font-smoothing(off);
                line-height: 1.5;
                font-size: 1rem;
                float: left;
                display: inline;
                margin-left: 3rem;
            }
        }
    }

    @include breakpoint($for-desktop-up) {
        @include container(60em);
    }

    @include breakpoint($for-large-desktop-up) {
        @include container(75em);
    }
}