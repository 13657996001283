.magic-checkbox + label {
  &:before {
    border-radius: 3px;
  }
  &:after {
    top: 2px;
    left: 7px;
    box-sizing: border-box;
    width: 6px;
    height: 12px;
    transform: rotate(45deg);
    border-width: 2px;
    border-style: solid;
    border-color: #fff;

    border-top: 0;
    border-left: 0;
  }
}
.magic-checkbox:checked + label {
  &:before {
    border: $magic-main-color;
    background: $magic-main-color;
  }
}
.magic-checkbox:checked[disabled] + label {
  &:before {
    border: $magic-disabled-checked-color;
    background: $magic-disabled-checked-color;
  }
}
