.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 273px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  // will-change: transform;
  background-color: color(background);
  /* A background-color is required */
  min-height: 100vh;
}

.slideout-open {
  overflow: hidden;
  body, .slideout-panel {
    overflow: hidden;
  }
  .slideout-menu {
    display: block;
  }
}

.slideout-panel:before {
  content: '';
  display: block;
  background-color: rgba(0,0,0,0);
  transition: background-color 0.2s ease-in-out;
}

.panel-open:before {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0,0,0,.5);
  z-index: 99;
}