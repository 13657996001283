.mobile-navigation {
    display: none;
    background-color: color(solitude);

    > .quick-bar {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        height: 4.25rem;

        > .item {
            display: flex;
            position: relative;
            flex-grow: 1;
            align-self: stretch;
            align-items: center;
            text-align: center;
            justify-content: center;
            background-color: color(dodgerblue, base);
            color: color(white, base);
            font-size: 1.75rem;
            text-decoration: none;
            outline: none;

            &:not(:first-child) {
                border-left: 2px solid color(solitude);
            }

            &:hover,
            &:active,
            &:visited {
                text-decoration: none;
            }
        }
    }

    > .activation-toggle {
        padding: 1rem 1.25rem;
        background-color: color(white, base);
        background-color: color(solitude);
        border-bottom: 2px solid color(solitude);

        > .switch {
            float: right;
            width: 30%;

            .switch-ios.switch-light input:checked ~ span {
                    border-color: color(shamrock, base);
                    box-shadow: inset 0 0 0 30px color(shamrock, base);
            }
        }
    }

    > .menu {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            &.button {
                display: block;
                border: 0 none;
                font-size: 1.125rem;
                border-radius: 0;
                font-weight: bold;
                &:hover {
                    background-color: color(white, base);
                    margin-top: 1px;
                }
            }

            @include font-smoothing(on);
            padding: 1rem 1.25rem;
            background-color: color(white, base);
            border-bottom: 2px solid color(solitude);

            &.active, &.current-menu-item {
                border-left: 5px solid color(violetred, base);
            }

            > .item {
                display: block;
                line-height: 1.618;
                text-decoration: none;

            }
        }
    }
}