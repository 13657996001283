.download-list {
    padding: 0;
    margin: 0;
    list-style: none;
    box-shadow: 0 1px 4px 0 rgba(119,135,147,0.1), 0 20px 40px 0 rgba(119,135,147,0.15);

    > .item {
        display:block;
        text-align: left;
        box-shadow: inset 0 0 0 1px color(solitude);

        > a {
            display:block;
            padding: 1rem;

            &:hover {
                transform: translateY(-1px);
                background-color: darken(color(white, base), 2%);
                box-shadow: 0 1px 4px 0 rgba(119,135,147,0.1), 0 20px 40px 0 rgba(119,135,147,0.15);
            }
        }
    }
}
