.header-logo {
    @include span(4 of 4);
    display: block;
    width: auto;
    @include breakpoint($for-desktop-up) {
        @include with-layout($twelve-columns);
        @include span(2 of 12);
        float: left;
    }

    > .toggle-button {
        float: left;
        margin-right: gutter(4);
        @include breakpoint($for-desktop-up) {
            display: none;
        }
    }

    a {
        float: left;
        display: block;
        max-width: 80%;
        padding: 0.875rem 0 0 0;
    }

    svg {
        height: 4rem;

        path {
            fill: color(white, base);
        }

        &.-small {
            height: 2rem;
        }
    }

    img {
        height: 1.5em;
        vertical-align: top;
        max-width: 80%;
        @include breakpoint($for-desktop-up) {
            vertical-align: middle;
        }

    }

    &.-hide {
        display:none;
    }

    @media (max-width: 980px) {
        float: right;

        a {
            padding: 0.5rem 0 0.5rem 0;
        }
    }
}