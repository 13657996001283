.notification-box {
    @include container(95%);
    @include font-smoothing(on);
    display: block;
    border: 2px solid rgba(110,176,226,1);
    background-color: rgba(233,241,247,1);
    color: rgba(41,100,144,1);
    padding: gutter();
    margin-bottom: 1rem;

    > .title {
        margin-top: 0;
        margin-bottom: 0.75rem;
        font-size: 1.125rem;
    }

    > .message {
        margin: 0;
    }

    > .additional-link {
        font-size: 1.125rem;
    }

    &.-alert {
        color: rgba(169,68,66,1);
        border: 2px solid rgba(169,68,66,1);
        background-color: lighten(rgba(169,68,66,1),48%);
    }

    &.-info {
        color: rgba(41,100,144,1);
        border: 2px solid rgba(110,176,226,1);
        background-color: rgba(233,241,247,1);
    }

    &.-warning {
        color: rgba(138,109,59,1);
        border: 2px solid rgba(250,242,204,1);
        background-color: rgba(252,248,227,1);
    }

    &.-success {
        color: color(shamrock, base);
        border: 2px solid color(shamrock, base);
        background-color: lighten(color(shamrock, base), 40%);
    }

    > ul {
        padding-left: 2rem;
    }

    @include breakpoint($for-desktop-up) {
        @include container(60em);
    }

    @include breakpoint($for-large-desktop-up) {
        @include container(75em);
    }
}
