.hero-container {
    @include container(100%);
    height: 100%;
    position: relative;
    color: color(white, base);
    display: block;
    padding-top: 4.25rem;
    background: url('../images/texture.png'), linear-gradient(0.25turn, #5ccaf2, #5cc699, #56c267);

    @include breakpoint($for-tablet-landscape-up) {
        height: 49em;
    }

    @include breakpoint($for-desktop-up) {
        padding-top: 6rem;
    }

    &.-small {
        height: auto;
        min-height: 13rem;
        margin-bottom: 2rem;

        > .container {
            padding-top: 2rem;
            padding-bottom: 1rem;
        }

        > .column {
            > .cta-wrapper {
                @include span(4 of 4);
                height: 100%;
                top: initial;
                left: initial;
                position: initial;
                transform: initial;
                > .title {
                    font-size: 3.375rem;
                    line-height: 3.75rem;
                    font-weight: 300;
                    letter-spacing: -1.28px;
                    margin-top: 0;
                    margin-bottom: 1rem;
                    text-transform: none;
                }
                > .claim {
                    margin-top: 3rem;
                    margin-bottom: 7.5rem;
                }
            }

            .profile-image {
                position: relative;
                @include centered(true,null);
                border: 3px solid color(bluemine, base);
                border-radius: 4.5rem;
                height: 9rem;
                width: 9rem;
                bottom: -4.5rem;
                background-size: contain;

                > .button {
                    margin-top: 9.5rem;
                }

                > .inner {
                    width: 6.15rem;
                    height: 6.15rem;
                    @include centered(true, true);
                }
            }

        }
    }

    &.-large-margin-bottom {
        margin-bottom: 9.5rem;
    }

    &.-no-margin-bottom {
        margin-bottom: 0;
    }

    > .container {
        @include container(95%);
        @include font-smoothing(on);
        position: relative;
        height: 100%;
        clear: both;
        text-align: center;
        padding-top: 4.5rem;
        text-align: center;
        > .title {
            // @include span(4 of 4);
            display: block;
            font-size: 3.375rem;
            line-height: 3.75rem;
            font-weight: 300;
            letter-spacing: -1.28px;
            margin-top: 0;
            margin-bottom: 1rem;
        }

        > .claim {
            // @include span(4 of 4);
            margin: 0 auto 1.5rem;
            max-width: 95%;
            font-size: 1.5rem;
            text-align: center;
            line-height: 1.5;
        }

        @include breakpoint($for-desktop-up) {
            @include container(60em);
        }

        @include breakpoint($for-large-desktop-up) {
            @include container(75em);
        }
    }

    > .column {
        @include span(4 of 4 nested)
        @include centered(true,null);
        @include font-smoothing(on);

        position: relative;
        text-align: center;

        > .cta-wrapper {
            position: relative;
            @include span(4 of 4);
            @include centered(true, null);

            > .title {
                font-size: 1.125rem;
                line-height: 1.3333rem;
                font-weight: 700;
                text-transform: uppercase;
                margin-top: 0;
                margin-bottom: 1rem;
            }

            > .claim {
                font-size: 2.25rem;
                line-height: 1.618;
                margin-top: 0;
                margin-bottom: 2rem;

                @include breakpoint($for-tablet-landscape-up) {
                    margin-bottom: 3rem;
                }
            }

            @include breakpoint($for-tablet-landscape-up) {
                @include span(8 of 12);
                @include gutters(0em);
            }
        }

    }
}
