.site-header-wrapper {
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 0;

    &.-backgroundgradient {
        position: fixed;
        background: url('../images/texture.png'), linear-gradient(0.25turn, #5ccaf2, #5cc699, #56c267);
    }

    &.-backgroundimage {
        background-image: url('../images/background.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        top: -27rem;

        h2 {
            font-size: 2rem;
            margin: 3rem 0 5rem 0;
            color: color(white, base);
            text-align: center;
        }
    }

    &.front-page-hide {
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transition: opacity 0.5s ease-out;
        -moz-transition: opacity 0.5s ease-out;
        -ms-transition: opacity 0.5s ease-out;
        -o-transition: opacity 0.5s ease-out;
        transition: opacity 0.5s ease-in-out;
    }

    &.front-page-hide-large {
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transition: opacity 0.5s ease-out;
        -moz-transition: opacity 0.5s ease-out;
        -ms-transition: opacity 0.5s ease-out;
        -o-transition: opacity 0.5s ease-out;
        transition: opacity 0.5s ease-out;
    }

    &.front-page-show {
        filter: alpha(opacity=1);
        opacity: 1;
        -webkit-transition: opacity 0.5s ease-in;
        -moz-transition: opacity 0.5s ease-in;
        -ms-transition: opacity 0.5s ease-in;
        -o-transition: opacity 0.5s ease-in;
        transition: opacity 0.5s ease-in;
    }
}

.site-header {
    @include container(100%);
    margin-top: 0.75rem;

    @include breakpoint($for-desktop-up) {
        margin-top: 1rem;
        margin-bottom: 1rem;

        > .primary-navigation {
            float: right;
            margin-top: 1em;
            height: 2em;
        }
    }

    @include breakpoint($for-desktop-up) {
        @include container(60em);
    }

    @include breakpoint($for-large-desktop-up) {
        @include container(75em);
    }

    .header-backgroundimage {
        @include container(100%);
        @include span(4 of 4);
        float: none;

        &.-hide {
            display: none;
        }

        svg {
            height: 10rem;
            display: block;
            margin: 0 auto;
            padding-top: 4rem;
        }
    }
}