.registration-box {
    @include container(100%);
    padding: 6rem 0 1.5rem;
    > .inner {
        @include span(4 of 4);
        > .title {
            margin-top: 0;
            font-size: 3.375rem;
            line-height: 3.75rem;
            font-weight: 300;
            text-align: center;
            margin-bottom: 1rem;
        }

        > .subtitle {
            font-size: 1.125rem;
            text-align: center;
            margin-top: 0;
            color: color(black, lighten40);
            font-weight: normal;
        }

        > .register-form {
            margin: 2rem 0;
            padding: 3rem 2rem;
            border-radius: 6px;
            background-color: color(white, base);
            box-shadow: 0 1px 4px 0 rgba(119,135,147,0.1), 0 20px 40px 0 rgba(119,135,147,0.15);

            > .form-section {
                margin-bottom: 2rem;
                overflow: auto;

                > .title {
                    margin: 0;
                    margin-bottom: 1rem;
                    font-size: 1.125rem;
                    font-weight: normal;
                    padding-bottom: 1rem;
                    border-bottom: 1px solid transparentize(color(black, base), 0.9);
                }
            }

            > .button {
                margin-top: 1rem;
                width: 100%;
            }

            .date {
                display: flex;
                flex-direction: column;

                @include breakpoint($for-tablet-portrait-up) {
                    flex-direction: row;
                    justify-content: space-between;
                }

                > select {
                    display: inline-block;

                    @include breakpoint($for-tablet-portrait-up) {
                        width: calc(1/3*100% - (1 - 1/3)*1rem);
                    }
                }
            }
        }

        .form-errors {
            margin-top: 1rem;
            color: rgba(169,68,66,1);
            font-size: 1rem;
        }
    }

    @include breakpoint($for-desktop-up) {
        padding: 7.5rem 0 1.5rem;
        @include container(60em);

        > .inner {
            @include span(10 of 12);
            float: none;
            margin: 0 auto;

            > .register-form {

                .column {
                    @include span(6 of 12 no-gutter);
                    padding: 0 gutter(12);
                    &:first-of-type {
                        padding-left: 0;
                    }

                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }

            &.-small {
                @include span(5 of 12);
                float: none;
                margin: 0 auto;
            }
        }
    }

    @include breakpoint($for-large-desktop-up) {
        @include container(75em);
    }
}
