.site-content-wrapper {
	position: relative;
	@include container(95%);
	margin-top: 7.25rem;

	background-image: url('../images/ijob-background.svg');
    background-repeat: repeat-y;
    background-position: center 0;
    background-size: 100% auto;
    background-color: color(white, base);

    box-shadow: 0 1px 4px 0 rgba(119, 135, 147, 0.5), 0 5px 10px 0 rgba(119, 135, 147, 0.75);
    border-radius: 4px;
    z-index: 10;

	&.-large-header {
		margin-top: 27rem;
	}

	@include breakpoint($for-desktop-up) {
        @include container(90%);
    }

    @include breakpoint($for-large-desktop-up) {
        @include container(80%);
    }
}